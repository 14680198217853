<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
              銷售應用中心
            </b-breadcrumb-item>
            <b-breadcrumb-item active>發票列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
          <h4 class="col-12 col-xl-2 mb-1 mb-xl-0 font-weight-bold">
            發票列表
          </h4>
          <div
            class="
              col-12 col-xl-8
              d-flex
              flex-column flex-xl-row
              align-items-start align-items-xl-center
            "
          >
            <b-form-select
              v-model="query.merchant_id"
              :options="merchants"
              @change="handlePageReset"
              :disabled="merchants.length == 2"
            >
            </b-form-select>
            <b-input-group class="ml-0 ml-xl-4">
              <b-form-input
                placeholder="搜尋發票號碼、會員名稱、手機、外部編碼"
                v-model="query.keyword"
                v-on:keyup.enter="handlePageReset"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="handlePageReset"
                  ><i class="fa fa-search"></i
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <b-table
          responsive
          striped
          hover
          :items="invoices"
          :fields="fields"
          @sort-changed="handleSort"
        >
          <template #cell(name)="data">
            <router-link
              :to="{
                name: 'InvoiceDetail',
                params: {
                  invoice_id: data.item.id,
                },
              }"
            >
              <div class="mb-1">{{ data.item.title }}</div>
              <div>{{ data.item.sale_page_code }}</div>
            </router-link>
          </template>
          <template #cell(created_at)="data">
            <div class="mb-1">
              {{ formatDate(data.item.created_at) }}
            </div>
          </template>
          <template #cell(invoice_date)="data">
            <div class="mb-1">
              {{ formatDate(data.item.invoice_date) }}
            </div>
          </template>
          <template #cell(branch)="data">
            <div class="mb-1">
              {{ data.item.branch ? data.item.branch.name : "" }}
            </div>
            <div>
              {{ data.item.branch ? data.item.branch.branch_code : "" }}
            </div>
          </template>
          <template #cell(arrange)="data">
            <b-button
              class="ml-2"
              variant="inverse-primary"
              v-if="checkPermission([consts.INVOICE_LIST_VIEW])"
              :to="{
                name: 'InvoiceDetail',
                params: {
                  invoiceId: data.item.id,
                },
              }"
              ><span class="mdi mdi-eye mr-1"></span>查看
            </b-button>
          </template>
        </b-table>
        <CustomPagination
          :currentPage="query.page"
          :totalRows="totalRows"
          :perPage="query.per_page"
          @page-change="handlePageChange"
          @per-page-change="handlePerPageChange"
        />
      </b-overlay>
    </b-card>
  </div>
</template>
<script>
import merchantApi from "../../../apis/merchant";
import invoiceApi from "../../../apis/invoice";
import moment from "moment";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";

export default {
  components: { CustomPagination },
  data() {
    return {
      consts,
      showLoading: false,
      merchants: [{ value: null, text: "請選擇" }],
      totalRows: 0,
      fields: [
        {
          key: "customer_name",
          label: "會員名稱",
          sortable: true,
          sortKey: "customer_name",
        },
        {
          key: "invoice_number",
          label: "發票號碼",
        },
        {
          key: "invoice_date",
          label: "開立日期",
          sortable: true,
          sortKey: "invoice_date",
          sortDirection: "desc",
        },
        {
          key: "random",
          label: "隨機碼",
        },
        {
          key: "seller_name",
          label: "開立機構",
        },
        {
          key: "total_price",
          label: "金額",
        },
        {
          key: "invoice_year",
          label: "年份",
        },
        {
          key: "invoice_period",
          label: "期別",
          sortable: true,
          sortKey: "invoice_period",
        },
        {
          key: "created_at",
          label: "建檔日期",
          sortable: true,
          sortKey: "created_at",
        },
        {
          key: "arrange",
          label: "管理",
        },
      ],
      invoices: [],
      permissionChecker: new PermissionChecker(),
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
        keyword: "",
        sort_by: "created_at",
        order_by: "desc",
        merchant_id: null,
      },
    };
  },
  created() {
    this.query = updateQueryFromRoute(
      this.$route,
      this.$store,
      this.query
    );
  },
  async mounted() {
    await this.fetchMerchants();
    this.initialized = true;
  },
  computed: {
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      this.query = { ...to.query };
      this.fetchInvoices();
    },
  },
  methods: {
    async fetchInvoices() {
      if (!this.query.merchant_id) return;

      this.showLoading = true;
      try {
        const params = {
          ...this.query,
        };

        const { data } = await invoiceApi.getInvoices(params);

        this.invoices = data.data.map((invoice) => ({
          customer_name: invoice?.customer?.name,
          ...invoice,
        }));
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        alert("取得發票資料錯誤");
      }
      this.showLoading = false;
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list();

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];
      if (!this.query.merchant_id) {
        const merchants = data.data;
        const defaultMerchant =
          merchants.length === 1
            ? merchants[0]
            : merchants.find((merchant) => merchant.type === consts.MERCHANT_TYPES.LINE_TYPE);

        if (defaultMerchant) {
          this.query.merchant_id = defaultMerchant.id;
        }
      }

      if (this.query.merchant_id) {
        const hasMerchant = this.merchants.find(
          (item) => item.value === this.query.merchant_id
        );
        if (!hasMerchant) {
          this.$swal.fire({
            icon: "warning",
            title: "查無此通路",
            confirmButtonText: "確定",
            confirmButtonColor: "#d33",
            reverseButtons: true,
          });
          return;
        }
        await this.fetchInvoices();
      }
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
    checkPermission(permissions) {
      return this.permissionChecker.check(permissions);
    },
    handleSort(ctx) {
      this.query.sort_by = ctx.sortBy;
      this.query.order_by = ctx.sortDesc ? "desc" : "asc";
      this.handlePageReset();
    },
    handlePageChange(newPage) {
      this.query.page = newPage;
    },
    handlePerPageChange(newPerPage) {
      this.query.per_page = newPerPage;
      this.query.page = 1;
    },
    handlePageReset() {
      const currentPage = parseInt(this.query.page, 10);

      if (currentPage === 1) {
        updateUrl(this.query, this.$store, this.$router);
      } else {
        this.query.page = 1;
      }
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>
