var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"}),_vm._v(" 銷售應用中心 ")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("發票列表")])],1)],1)])]),_c('b-card',[_c('b-overlay',{attrs:{"show":_vm.showLoading,"rounded":"sm"}},[_c('div',{staticClass:"row d-flex mb-4 mb-xl-2 justify-content-between"},[_c('h4',{staticClass:"col-12 col-xl-2 mb-1 mb-xl-0 font-weight-bold"},[_vm._v(" 發票列表 ")]),_c('div',{staticClass:"\n            col-12 col-xl-8\n            d-flex\n            flex-column flex-xl-row\n            align-items-start align-items-xl-center\n          "},[_c('b-form-select',{attrs:{"options":_vm.merchants,"disabled":_vm.merchants.length == 2},on:{"change":_vm.handlePageReset},model:{value:(_vm.query.merchant_id),callback:function ($$v) {_vm.$set(_vm.query, "merchant_id", $$v)},expression:"query.merchant_id"}}),_c('b-input-group',{staticClass:"ml-0 ml-xl-4"},[_c('b-form-input',{attrs:{"placeholder":"搜尋發票號碼、會員名稱、手機、外部編碼"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handlePageReset.apply(null, arguments)}},model:{value:(_vm.query.keyword),callback:function ($$v) {_vm.$set(_vm.query, "keyword", $$v)},expression:"query.keyword"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":_vm.handlePageReset}},[_c('i',{staticClass:"fa fa-search"})])],1)],1)],1)]),_c('b-table',{attrs:{"responsive":"","striped":"","hover":"","items":_vm.invoices,"fields":_vm.fields},on:{"sort-changed":_vm.handleSort},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('router-link',{attrs:{"to":{
              name: 'InvoiceDetail',
              params: {
                invoice_id: data.item.id,
              },
            }}},[_c('div',{staticClass:"mb-1"},[_vm._v(_vm._s(data.item.title))]),_c('div',[_vm._v(_vm._s(data.item.sale_page_code))])])]}},{key:"cell(created_at)",fn:function(data){return [_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.formatDate(data.item.created_at))+" ")])]}},{key:"cell(invoice_date)",fn:function(data){return [_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.formatDate(data.item.invoice_date))+" ")])]}},{key:"cell(branch)",fn:function(data){return [_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(data.item.branch ? data.item.branch.name : "")+" ")]),_c('div',[_vm._v(" "+_vm._s(data.item.branch ? data.item.branch.branch_code : "")+" ")])]}},{key:"cell(arrange)",fn:function(data){return [(_vm.checkPermission([_vm.consts.INVOICE_LIST_VIEW]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-primary","to":{
              name: 'InvoiceDetail',
              params: {
                invoiceId: data.item.id,
              },
            }}},[_c('span',{staticClass:"mdi mdi-eye mr-1"}),_vm._v("查看 ")]):_vm._e()]}}])}),_c('CustomPagination',{attrs:{"currentPage":_vm.query.page,"totalRows":_vm.totalRows,"perPage":_vm.query.per_page},on:{"page-change":_vm.handlePageChange,"per-page-change":_vm.handlePerPageChange}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }